import React, { useState, useEffect, Fragment } from "react"


// const contentful = require("contentful")
// //eslint-disable-next-line
// const client = contentful.createClient({
//   space: `udjiynltatls`,
//   accessToken: `f4_qmqx5bOCb6_wimJGhNd7kB8rLgTaEzvXLvW_p6uA`
// })

// export const query = graphql`
//     query {
//       allContentfulNewsTags {
//         edges {
//           node {
//             slug
//             title
//             locationsTags
//             typestags
//             ordering
//           }
//         }
//       }
//     }
//   `

const Test = props => {
 console.log('what is test props', props)
 return (
     <div>test page</div>
 )
}

export default Test